import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import TopMenu from '../components/TopMenu';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import Modals from './Modals.js';
import history from '../history';
import $ from "jquery";
import moment from "moment";

export default class DailyPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      purchaseHistory:[],
      loading:false
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();

    if (user !== null) {
      this.setState({ user: user, loggedin: true });

    }
  };

  render() {
    const { purchaseHistory } = this.state;
    
    return (
      <div id="wrapper">

        <Sidemenu />
        
        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

            <TopMenu />

            <div className="container"><hr /></div>


            {/* questions */}
            {(this.state.loading==true)&& <center><Spinner bg={"#fff"} fill={"#444"} /><br/></center>}
            <div className="container">
              <div className="row">
                
                <div className="col-lg qborder">
                  <div className="card-header question">
                    <h6 className=" ">nCarta</h6>
                  </div>
                  <div className="list-group quiz">
                    <p>nCarta – nCarta is a platform that seeks to educate the general public by answering various questions across various categories on their phones, Tablets and computers, building points toward being the most knowledgeable on the platform and being rewarded as well. The Goal is to educate and at the same time entertain users. nCarta will allow players to participate in a quiz, answer questions & learn by playing digitally on their phones, tablets and computers.</p>
                  </div>
                </div>
              </div>
              {/* questions-end */}




            </div>

            <hr />

            {/* </div> */}

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

          <Modals/>
        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    );
  }
}
