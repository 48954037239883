import React, { Component } from 'react';
import $ from 'jquery';
import { getUserData } from "../common";
import history from "../history";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            user: {},
            profilePic: '',
            loggedin: false
        };
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var user = await getUserData();
        if (user !== null) {
            this.setState({ user: user, loggedin: true });
        }
    };

    logout() {
        localStorage.removeItem('userData');
        history.push('/');
        window.location.href = window.location.href;
    }

    render() {
        const { loggedin } = this.state;
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                    <div className="sidebar-brand-icon rotate-n-15">
                        {/* <i className="fas fa-laugh-wink"></i> */}
                        <img className="rounded-circle" width="62px" src="img/logo.png"
                            alt="..." />
                    </div>
                    <div className="sidebar-brand-text mx-3">nCarta </div>
                </a>

                {/* Divider */}
                <hr className="sidebar-divider my-0" />

                {/* Nav Item - Dashboard */}
                <li className="nav-item">
                    <a className="nav-link" href="/">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                </li>

                {/* Divider */}
                <hr className="sidebar-divider" />

                {/* Heading */}
                <div className="sidebar-heading">
                    ncarta
                </div>

                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/play">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Play Game</span></a>
                </li>}

                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/purchases">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Purchase History</span></a>
                </li>}

                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/dailypoints">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Daily Points</span></a>
                </li>}

                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/rewards">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>My Reward</span></a>
                </li>}

                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/gamepoints">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Game Points</span></a>
                </li>}
                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/leaderboard">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Leader Board</span></a>
                </li>}

                {/* Divider */}
                {(loggedin==true)&&<hr className="sidebar-divider" />}

                {(loggedin==true)&&
                <div className="sidebar-heading">
                    Profile
                </div>}



                {(loggedin==true)&&
                <li className="nav-item">
                    <a className="nav-link" href="/profile">
                        <i className="fas fa-fw fa-users"></i>
                        <span>My Account</span></a>
                </li>}

                {/* Divider */}
                {(loggedin==true)&&
                <hr className="sidebar-divider d-none d-md-block" />}

                <li className="nav-item">
                    <a className="nav-link" href="/download">
                        <i className="fas fa-fw fa-download"></i>
                        <span>Download App</span></a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" href="/aboutus">
                        <i className="fas fa-fw fa-info"></i>
                        <span>About Us</span></a>
                </li>
                {/* Sidebar Toggler (Sidebar) */}
                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"></button>
                </div>

            </ul>
        );
    }
}
