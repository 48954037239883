import React, { Component } from 'react';
import $ from 'jquery';
import { GetData, getUserData, urls,imgPath } from "../common";
import history from "../history";
import moment from 'moment';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            user: {},
            profilePic: '',
            loggedin: false,
            challenges:{},
            userPoints:{}
        };
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var user = await getUserData();

        if (user !== null) {
            this.setState({ user: user, loggedin: true });

            var challenges = await GetData(urls.challenges);
            const date = moment(); // Thursday Feb 2015
            let dow = date.day();
            if(dow===0)
            dow=7; 
            
            dow=dow-1;
            
            if (challenges.code === 200) {
                this.setState({challenges:challenges.message[dow]});
            }

        }else{
            this.setState({ user: {}});
        }
    };

    logout() {
        localStorage.removeItem('userData');
        history.push('/');
        window.location.href = window.location.href;
    }

    render() {
        const { loggedin, user,challenges } = this.state;

        return (
            <div>
                {/* Topbar */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    {/* Sidebar Toggle (Topbar) */}
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    {/* Topbar Search */}
                    {/* <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form> */}

                    {/* Topbar Navbar */}
                    {(loggedin == true) ?
                        <ul className="navbar-nav ml-auto">

                            {/* Nav Item - Messages */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-envelope fa-fw"></i>
                                    {/* Counter - Messages */}
                                    <span className="badge badge-danger badge-counter">0</span>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="messagesDropdown">
                                    <h6 className="dropdown-header">
                                        Message Center
                                    </h6>
                                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div className="font-weight-bold">
                                            <div className="text-truncate">Hi there!I am wondering if you can help me with a
                                                problem I've been having.</div>
                                            <div className="small text-gray-500">Emily Fowler · 58m</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                alt="..." />
                                            <div className="status-indicator"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">I have the photos that you ordered last month, how
                                                would you like them sent to you?</div>
                                            <div className="small text-gray-500">Jae Chun · 1d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-warning"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Last month's report looks great, I am very happy with
                                                the progress so far, keep up the good work!</div>
                                            <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Am I a good boy?The reason I ask is because someone
                                                told me that people say this to all dogs, even if they aren't good...</div>
                                            <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a> */}
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(user.screen_name != undefined) ? user.screen_name : ""}</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                                {/* Dropdown - User Information */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="/profile">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </a>

                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={() => this.logout()}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>

                        </ul> : <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#loginModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Login </span>
                                   
                                </a>
                            </li>
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#registerModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Register</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                            </li>
                        </ul>
                    }

                </nav>
                {/* End of Topbar */}

                {/* Begin Page Content */}
                <br />
                <br />
            </div>
        );
    }
}
