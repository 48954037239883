import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import ProfileMenu from '../components/ProfileMenu';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import history from '../history';
import $ from "jquery";
import moment from 'moment';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      mobile: "",
      password: "",
      userPoints: "",
      position: "",
      userSpins: "",
      playlist: [],
      buySelection: {},
      purchaseMsg: "",
      selectednetwork: "",
      purchaseLoading: false
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();

    if (user !== undefined || user !== null) {
      this.setState({ user: user, loggedin: true });

      // var userPoints = await GetData(urls.points);
      // if (userPoints.code === 200) {
      //   this.setState({ userPoints: userPoints.message });
      // }


      var userPoints = await GetData(urls.points);
      if (userPoints.code === 200) {
          this.setState({ userPoints: userPoints.message });
      }

      var userSpins = await GetData(urls.spins);
      if (userSpins.code === 200) {
          this.setState({ userSpins: userSpins.message });
      }

      var userPosition = await GetData(urls.position);
      if (userPosition.code === 200) {
          this.setState({ position: userPosition.message });
      }

      var playlist = await GetData(urls.playlist);
      if (playlist.code === 200) {
          this.setState({ playlist: playlist.message });
      }

      
    }
  };

  render() {
    const { loginMsg, loginLoading, userPoints, position, userSpins, playlist,
      purchaseMsg, purchaseLoading } = this.state;
    
    return (
      <div id="wrapper">

        <Sidemenu />
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

            <ProfileMenu />

            {/* Leader board / notices */}
            <div className="container d-none d-sm-block">
              <div className="row grey-border b-pa  ">
                <div className="col-sm r-line">
                  <img className="img-fluid  mt-3  howitworks" style={{ width: "25rem" }} src="img/howitworks.png" alt="..." />
                </div>

                <div className="col-sm r-line score-tip  ">
                  <div className="score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">My Points</h6>
                  </div>
                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your daily points
                      <span className="badge badge-primary badge-pill">{userPoints}</span>
                    </li>

                  </ul>

                </div>

                <div className="col-sm r-line ">
                  <div className="card-header score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">My Plays</h6>
                  </div>

                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your spins left
                      <span className="badge badge-primary badge-pill">{userSpins}</span>
                    </li>

                  </ul>


                </div>

                <div className="col-sm ">

                  <div className="card-header score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">Leaderboard</h6>
                  </div>

                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your position is
                      <span className="badge badge-primary badge-pill">{position}</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            {/* leader board / notices end */}

            <div className="container"><hr /></div>
            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

      </div>
    );
  }
}
