import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import TopNav from '../components/TopNav';
import { getUserData, PostData, GetData, urls,imgPath} from "../common";
import Spinner from '../Spinner.js';
import history from '../history';
import $ from "jquery";
import moment from 'moment';
import Modals from './Modals.js';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      mobile: "",
      password: "",
      userPoints: "",
      position: "",
      userSpins: "",
      playlist: [],
      buySelection: {},
      purchaseMsg: "",
      selectednetwork: "",
      purchaseLoading: false,
      activationMsg: "",
      code: ""
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();
    
        if (user !== null) {
            this.setState({ user: user, loggedin: true });
        }
  };

  render() {
    const { loggedin,user, loginMsg, loginLoading, userPoints, position, userSpins, playlist,
      purchaseMsg, purchaseLoading, activationMsg } = this.state;
    let playlistItems = [];
    for (var p in playlist) {
      const data = playlist[p];
      let selected = false;
      if (this.state.buySelection.id != undefined)
        if (this.state.buySelection.id == data.id)
          selected = true;


      if (selected == false) {
        playlistItems.push(<a href="#" onClick={() => this.setState({ buySelection: data })}>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {data.name} - {data.plays}plays
            <span className="badge badge-success badge-pill">¢{data.price}</span>
          </li></a>);
      } else {
        playlistItems.push(<li className="list-group-item d-flex justify-content-between align-items-center blk-bg">
          {data.name} - {data.plays}plays
          <span className="badge badge-success badge-pill">¢{data.price}</span>
        </li>);
      }
    }

    return (
      <div id="wrapper">

        <Sidemenu />
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

          <div>
                {/* Topbar */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    {/* Sidebar Toggle (Topbar) */}
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    {/* Topbar Search */}
                    {/* <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form> */}

                    {/* Topbar Navbar */}
                    {(loggedin == true) ?
                        <ul className="navbar-nav ml-auto">

                            {/* Nav Item - Messages */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-envelope fa-fw"></i>
                                    {/* Counter - Messages */}
                                    <span className="badge badge-danger badge-counter">0</span>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="messagesDropdown">
                                    <h6 className="dropdown-header">
                                        Message Center
                                    </h6>
                                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div className="font-weight-bold">
                                            <div className="text-truncate">Hi there!I am wondering if you can help me with a
                                                problem I've been having.</div>
                                            <div className="small text-gray-500">Emily Fowler · 58m</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                alt="..." />
                                            <div className="status-indicator"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">I have the photos that you ordered last month, how
                                                would you like them sent to you?</div>
                                            <div className="small text-gray-500">Jae Chun · 1d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-warning"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Last month's report looks great, I am very happy with
                                                the progress so far, keep up the good work!</div>
                                            <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Am I a good boy?The reason I ask is because someone
                                                told me that people say this to all dogs, even if they aren't good...</div>
                                            <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a> */}
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(user.screen_name != undefined) ? user.screen_name : ""}</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                                {/* Dropdown - User Information */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="/profile">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </a>

                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={() => this.logout()}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>

                        </ul> : <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#loginModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Login </span>
                                   
                                </a>
                            </li>
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#registerModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Register</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                            </li>
                        </ul>
                    }

                </nav>
                {/* End of Topbar */}

                {/* Begin Page Content */}
                <br />
                <br />

                {/* profile */}
                <div className="container">
                    {(loggedin == true) ?
                        <div className="row ">
                            <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                                    <div className="d-none d-md-block d-lg-none" style={{width:120,height:120,borderRadius:120,overflow:'hidden',border:'5px solid #ff003c'}}>
                                        <img className="mr-3" src={(user.avatar!==undefined)?imgPath+user.avatar:"http://"} alt="" width="148" height="auto" />
                                    </div>
                                    <div className="lh-100 header-prf-sm">
                                        <div className="d-block d-sm-none" style={{width:120,height:120,borderRadius:120,overflow:'hidden',border:'5px solid #ff003c'}}>
                                            <img className="mr-3" src={(user.avatar!==undefined)?imgPath+user.avatar:"http://"} alt="" width="148" height="auto" />
                                        </div>
                                        <h6 className="mb-0  lh-100">Hi {user.screen_name}, </h6>
                                        <small>welcome back.</small>
                                        <hr />
                                        <a className="btn btn-success btn-prf btn-sm" href="/play"> Play Game!</a>
                                        {/* trigger Modal -silver play */}
                                        <a href="/" className="btn btn-success btn-prf btn-sm"><i className="fas fa-sync"></i></a>
                                        {/* trigger Modal - buy play*/}
                                        <a className="btn btn-success btn-prf btn-sm" data-toggle="modal" data-target="#buyplay" >Buy Play</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm header-prf  ">
                                <div className="media">
                                    <div className="media-body">
                                        <h5 className="mt-0 mb-1">How it works</h5>
                                    </div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="148" height="auto" />
                                </div>
                            </div>
                        </div> :
                        <div className="row ">
                            <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                                    <img className="mr-3" src="img/profile.png" alt="" width="148" height="auto" />
                                    <div className="lh-100 header-prf-sm">
                                        <h6 className="mb-0  lh-100">Hello There, </h6>
                                        <small>welcome to the challenge.</small>
                                        <hr />
                                        <a className="btn btn-success btn-prf btn-sm" href="#" data-toggle="modal" data-target="#loginModal" > Login</a>
                                        <a className="btn btn-success btn-prf btn-sm" href="#" data-toggle="modal" data-target="#registerModal" > Register</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm header-prf  ">
                                <div className="media">
                                    <div className="media-body">
                                                                           </div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="148" height="auto" />
                                </div>
                            </div>
                        </div>}
                </div>

            </div>

            {/* Leader board / notices */}
            <div className="container d-none d-sm-block">
              <div className="row grey-border b-pa  ">
                <div className="col-sm r-line">
                  <img className="img-fluid  mt-3  howitworks" style={{ width: "25rem" }} src="img/howitworks.png" alt="..." />
                </div>

                <div className="col-sm r-line score-tip  ">
                  <div className="score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">Game Play</h6>
                  </div>
                  
                  <b>1. Top play the trivia i need to have plays.</b><br/>
                  <b>2. Click on the "buy plays" button to buy any amount you want.</b><br/>
                  <b>3. After buying the plays click on the "play Game" button to start.</b><br/><br/><br/>
                </div>
              </div>
            </div>
            {/* leader board / notices end */}

            <div className="container"><hr /></div>

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
    <Modals/>
      </div>
    );
  }
}
