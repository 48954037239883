import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PurchaseHistory from "./pages/PurchaseHistory";
import DailyPoints from './pages/DailyPoints';
import GamePoints from './pages/GamePoints';
import Download from './pages/Download';
import AboutUs from "./pages/AboutUs"
import Profile from "./pages/Profile"
import Play from './pages/Play';
import Leaderboard from "./pages/Leaderboard"
import Rewards from "./pages/Rewards"
import Howitworks from "./pages/Howitworks"

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/purchases" element={<PurchaseHistory />} />
        <Route path="/dailypoints" element={<DailyPoints />} />
        <Route path="/gamepoints" element={<GamePoints />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/download" element={<Download />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/play" element={<Play />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/howitworks" element={<Howitworks />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);