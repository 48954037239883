import React, { Component } from 'react';
import $ from 'jquery';
import { GetData, getUserData, urls,imgPath } from "../common";
import history from "../history";
import moment from 'moment';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            user: {},
            profilePic: '',
            loggedin: false,
            challenges:{},
            userPoints:{},
            play:false
        };
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var user = await getUserData();

        if (user !== null) {
            this.setState({ user: user, loggedin: true });

            var challenges = await GetData(urls.challenges);
            const date = moment(); // Thursday Feb 2015
            let dow = date.day();
            if(dow===0)
            dow=7; 
            
            dow=dow-1;
            
            if (challenges.code === 200) {
                this.setState({challenges:challenges.message[dow]});
            }

        }else{
            this.setState({ user: {}});
        }
    };

    logout() {
        localStorage.removeItem('userData');
        history.push('/');
        window.location.href = window.location.href;
    }

    render() {
        const { loggedin, user,challenges } = this.state;

        return (
            <div>
                {/* Topbar */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    {/* Sidebar Toggle (Topbar) */}
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    {/* Topbar Search */}
                    {/* <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form> */}

                    {/* Topbar Navbar */}
                    {(loggedin == true) ?
                        <ul className="navbar-nav ml-auto">

                            
                            {/* Nav Item - Messages */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-envelope fa-fw"></i>
                                    {/* Counter - Messages */}
                                    <span className="badge badge-danger badge-counter">0</span>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="messagesDropdown">
                                    <h6 className="dropdown-header">
                                        Message Center
                                    </h6>
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(user.screen_name != undefined) ? user.screen_name : ""}</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                                {/* Dropdown - User Information */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="/profile">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </a>

                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={() => this.logout()}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>

                        </ul> : <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#loginModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">Login </span>
                                   
                                </a>
                            </li>
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#registerModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">Register</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                            </li>
                        </ul>
                    }

                </nav>
                {/* End of Topbar */}

                {/* Begin Page Content */}
                
                {/* profile */}
                <div className="container">
                    {(loggedin == true) ?
                        <div className="row ">
                            {/* <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                                    <div className=" d-none d-md-block d-sm-none" style={{width:120,height:120,borderRadius:120,overflow:'hidden',border:'5px solid #ff003c'}}>
                                        <img className="mr-3" src={(user.avatar!==undefined)?imgPath+user.avatar:"http://"} alt="" width="148" height="auto" />
                                    </div>
                                    <div className="lh-100 header-prf-sm">
                                        <h6 className="mb-0  lh-100">Hi {user.screen_name}, </h6>
                                        <small>Ready To Play?</small>
                                        <div className="d-none d-md-block d-sm-none">
                                            <hr className=""/>
                                            
                                            <a className="btn btn-success btn-prf btn-sm" href="/play"> Play Game!</a>
                                            <a href="/" className="btn btn-success btn-prf btn-sm"><i className="fas fa-sync"></i></a>
                                            <a className=" btn btn-success btn-prf btn-sm" data-toggle="modal" data-target="#buyplay" >Buy Play</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <a className="btn btn-success btn-prf btn-sm" href="/play"> Play Game!</a>
                                            <a href="/" className="btn btn-success btn-prf btn-sm"><i className="fas fa-sync"></i></a>
                                            <a className=" btn btn-success btn-prf btn-sm" data-toggle="modal" data-target="#buyplay" >Buy Play</a>

                            <div className="col-sm header-prf d-none d-sm-block d-md-none">
                                <div className="media">
                                    <div className="media-body">
                                        <h5 className="mt-0 mb-1" style={{fontSize:32,lineHeight:'1.6rem'}}>{(challenges.id!==undefined)?challenges.name+" "+challenges.description:""}</h5>
                                        Click here to start
                                    </div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="88" height="auto" />
                                </div>
                            </div>
                        </div> :
                        <div className="row ">
                            <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                                    <img className="mr-3" src="img/profile.png" alt="" width="148" height="auto" />
                                    <div className="lh-100 header-prf-sm">
                                        <h6 className="mb-0  lh-100">Hello There, </h6>
                                        <small>welcome to the challenge.</small>
                                        <hr />
                                        <a className="btn btn-success btn-prf btn-sm" href="#" data-toggle="modal" data-target="#loginModal" > Login</a>

                                        <a className="btn btn-success btn-prf btn-sm" data-toggle="modal" data-target="#registerModal" >Register</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm header-prf  ">
                                <div className="media">
                                    <div className="media-body"></div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="148" height="auto" />
                                </div>
                            </div>
                        </div>}
                </div>
                {/* profile End*/}

                <div className="container"><hr /></div>
                {/* d-xl-none */}
            </div>
        );
    }
}