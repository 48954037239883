import React, { Component } from 'react';
import $ from 'jquery';
import { GetData, baseUrl, getUserData, urls, imgPath, PutData } from "../common";
import history from "../history";
import moment from 'moment';
import Spinner from '../Spinner.js';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            user: {},
            profilePic: '',
            loggedin: false,
            challenges: {},
            userPoints: {},
            screen_name: "",
            loading: false,
            profileMsg: "",
            userPoints: "",
      position: "",
      userSpins: "",
        };
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var user = await getUserData();

        if (user !== null) {
            this.setState({ user: user, loggedin: true, screen_name: user.screen_name });
        } else {
            this.setState({ user: {} });
        }
    };

    updateProfile = async () => {
        this.setState({ loading: true });
        var data = await PutData(urls.user, {
            screen_name: this.state.screen_name
        }, true);
        if (data.code == 200) {
            this.getProfile();
            this.setState({ profileMsg: <small style={{ color: '', lineHeight: 0, fontSize: 10 }}>{data.message}</small> })
        } else {
            this.setState({ profileMsg: <small style={{ color: '#000', lineHeight: 0, fontSize: 10 }}>{data.message}</small> })
        }
        this.setState({ loading: false });
    }

    onchangeFile = event => {
        this.updateProfilePic(event.target.files[0]);
    }
    updateProfilePic = async (file) => {
        this.setState({ loading: true });
        var user = await getUserData();
        console.log(file);
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "photo",
            file,
            file.name
        );

        fetch(baseUrl + "users/user", {
            method: "PUT",
            headers: {
                'user-id': user.id,
                'access-token': user.access_token
            },
            body: formData
        })
            .then(response => response.text())
            .then(response => {
                let data = JSON.parse(response);
                if (data.code == 200) {
                    this.getProfile();
                    this.setState({ profileMsg: <small style={{ color: '', lineHeight: 0, fontSize: 10 }}>{data.message}</small> })
                } else {
                    this.setState({ profileMsg: <small style={{ color: '#000', lineHeight: 0, fontSize: 10 }}>{data.message}</small> })
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                this.setState({ profileMsg: <small style={{ color: '#000', lineHeight: 0, fontSize: 10 }}>Sorry we cnt upload you pic now.</small> })
            });

    }

    getProfile = async () => {
        var user = await GetData(urls.user);
        if (user.code === 200) {
            localStorage.setItem("userData", JSON.stringify(user.message));
        
            this.loadData();
        }
    }

    logout() {
        localStorage.removeItem('userData');
        history.push('/');
        window.location.href = window.location.href;
    }

    render() {
        const { loggedin, user, challenges,userPoints,
        position,userSpins } = this.state;

        return (
            <div>
                {/* Topbar */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    {/* Sidebar Toggle (Topbar) */}
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    {/* Topbar Navbar */}
                    {(loggedin == true) ?
                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item dropdown no-arrow mx-1">
                                <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-envelope fa-fw"></i>
                                    {/* Counter - Messages */}
                                    <span className="badge badge-danger badge-counter">0</span>
                                </a>
                                {/* Dropdown - Messages */}
                                <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="messagesDropdown">
                                    <h6 className="dropdown-header">
                                        Message Center
                                    </h6>
                                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div className="font-weight-bold">
                                            <div className="text-truncate">Hi there!I am wondering if you can help me with a
                                                problem I've been having.</div>
                                            <div className="small text-gray-500">Emily Fowler · 58m</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                alt="..." />
                                            <div className="status-indicator"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">I have the photos that you ordered last month, how
                                                would you like them sent to you?</div>
                                            <div className="small text-gray-500">Jae Chun · 1d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                                alt="..." />
                                            <div className="status-indicator bg-warning"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Last month's report looks great, I am very happy with
                                                the progress so far, keep up the good work!</div>
                                            <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <div className="dropdown-list-image mr-3">
                                            <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                alt="..." />
                                            <div className="status-indicator bg-success"></div>
                                        </div>
                                        <div>
                                            <div className="text-truncate">Am I a good boy?The reason I ask is because someone
                                                told me that people say this to all dogs, even if they aren't good...</div>
                                            <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a> */}
                                </div>
                            </li>

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(user.screen_name != undefined) ? user.screen_name : ""}</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                                {/* Dropdown - User Information */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <a className="dropdown-item" href="/profile">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Profile
                                    </a>

                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={() => this.logout()}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </a>
                                </div>
                            </li>

                        </ul> : <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#loginModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Login </span>
                                   
                                </a>
                            </li>
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="modal" data-target="#registerModal"
                                     aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-lg-inline text-gray-600 small">Register</span>
                                    <img className="img-profile rounded-circle"
                                        src="img/undraw_profile.svg" />
                                </a>
                            </li>
                        </ul>
                    }

                </nav>
                {/* End of Topbar */}

                {/* Begin Page Content */}
                <br />
                <br />


                {/* profile */}
                <div className="container">
                    {(loggedin == true) ?
                        <div className="row ">
                            <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow ">
                                    <div className="d-none d-md-block d-sm-none" style={{ width: 120, height: 120, borderRadius: 120, overflow: 'hidden', border: '5px solid #ff003c' }}>
                                        <input type="file" onChange={this.onchangeFile} style={{ position: 'absolute', top: 0, height: 120, opacity: 0, width: 120, border: '1px solid #ccc' }} />
                                        
                                        {(user.avatar != "") ?
                                            <img className="" src={(user.avatar !== undefined) ? imgPath + user.avatar+"?date="+moment().format("HHMMSS") : "http://"} alt="" width="110" height="auto" /> :
                                            <p style={{ width: '100%', textAlign: 'center', marginTop: 20, top: -120, position: 'absolute' }}>Click to add profile pic</p>}
                                    </div>
                                    <div className="lh-100 header-prf-sm">
                                        <div className="d-md-none d-sm-block" style={{ width: 120, height: 120, borderRadius: 120, overflow: 'hidden', border: '5px solid #ff003c' }}>
                                            <input type="file" onChange={this.onchangeFile} style={{ position: 'absolute', top: 0, height: 120, opacity: 0, width: 120, border: '1px solid #ccc' }} />
                                            
                                            {(user.avatar != "") ?
                                                <img className="" src={(user.avatar !== undefined) ? imgPath + user.avatar+"?date="+moment().format("HHMMSS") : "http://"} alt="" width="110" height="auto" /> :
                                                <p style={{ width: '100%', textAlign: 'center', marginTop: 20, top: -120, position: 'absolute' }}>Click to add profile pic</p>}
                                        </div>
                                        <h6 className="mb-0  lh-100">Hi {user.screen_name}, </h6>
                                        <font className="" style={{color:'#666',fontSize:14}}>{user.mobile}</font>
                                        
                                        <input type="text" className="form-control bg-light border-1 small"
                                            placeholder="Screen Name" aria-label="Mobile"
                                            value={this.state.screen_name}
                                            aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ screen_name: e.target.value })} style={{ marginLeft: 5, marginTop: 5 }} />

                                        <hr />
                                        {(this.state.loading == false) ?
                                            <a className="btn btn-success btn-prf btn-sm" href="#" onClick={() => this.updateProfile()}> Update Profile</a> :
                                            <Spinner bg={"#f8f9fc"} fill={"#444"} />}
                                            <br/>
                                        {(this.state.profileMsg !== "") && this.state.profileMsg}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm header-prf  ">
                                <div className="media">
                                    <div className="media-body">
                                        <h5 className="mt-0 mb-1">Profile Update</h5>    
                                    </div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="148" height="auto" />
                                </div>
                            </div>
                        </div> :
                        <div className="row ">
                            <div className="col-lg b-right ">
                                <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                                    <img className="mr-3" src="img/profile.png" alt="" width="148" height="auto" />
                                    <div className="lh-100 header-prf-sm">
                                        <h6 className="mb-0  lh-100">Hello There, </h6>
                                        <small>welcome to the challenge.</small>
                                        <hr />
                                        <a className="btn btn-success btn-prf btn-sm" href="#" data-toggle="modal" data-target="#loginModal" > Login</a>

                                        <a className="btn btn-success btn-prf btn-sm" data-toggle="modal" data-target="#buyplay" >Register</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm header-prf  ">
                                <div className="media">
                                    <div className="media-body"></div>
                                    <img className="image-fluid" src="img/rocket.png" alt="" width="148" height="auto" />
                                </div>
                            </div>
                        </div>}
                </div>
                {/* profile End*/}

                <div className="container"><hr /></div>
                
            </div>
        );
    }
}
