import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import TopNav from '../components/TopNav';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import history from '../history';
import $ from "jquery";
import moment from 'moment';
import Modals from './Modals.js';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      mobile: "",
      password: "",
      userPoints: "",
      position: "",
      userSpins: "",
      playlist: [],
      buySelection: {},
      purchaseMsg: "",
      selectednetwork: "",
      purchaseLoading: false,
      activationMsg: "",
      code: ""
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();
    
    if (user !== undefined || user !== null) {
      this.setState({ user: user, loggedin: true });

      var userPoints = await GetData(urls.points);
      
      if (userPoints.code === 200) {
        this.setState({ userPoints: userPoints.message });
      }else if(userPoints.code==900){
        localStorage.removeItem('userData');
      }

      var userSpins = await GetData(urls.spins);
      if (userSpins.code === 200) {
        this.setState({ userSpins: userSpins.message });
      }

      var userPosition = await GetData(urls.position);
      if (userPosition.code === 200) {
        this.setState({ position: userPosition.message });
      }

      var playlist = await GetData(urls.playlist);
      if (playlist.code === 200) {
        this.setState({ playlist: playlist.message });
      }
    }
  };

  payNow = async () => {
    this.setState({ purchaseLoading: true });

    var purchasing = await PostData(urls.purchasePlays, {
      network: this.state.selectednetwork,
      package: this.state.buySelection.id,
      voucher_code: ''
    }, true);
    if (purchasing.code == 200) {
      var loginMsg = <div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Process alert!</strong> {purchasing.message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ purchaseMsg: loginMsg });
      this.loadData();
      this.timeoutHandle = setTimeout(() => {
        window.$("#silverplay").modal("hide");
      }, 7000);
    } else {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Process alert!</strong> {purchasing.message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ purchaseMsg: loginMsg });
    }
    this.setState({ purchaseLoading: false });
  }


  Registration = async () => {
    if (this.state.mobile === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.mobile.length !== 10) {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is invalid eg 0202000000
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.password === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var payload = {
        "mobile": this.state.mobile,
        "screen_name": this.state.name,
        email: "",
        "password": this.state.password
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.user, payload, false);

      if (login.code == 200) {
        localStorage.setItem("activationMobile", JSON.stringify(payload));
        this.setState({ user: login.message });
        window.$("#registerModal").modal("hide");
        window.$("#activationModal").modal("show");

      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }


  logginIn = async () => {
    if (this.state.mobile === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.password === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var payload = {
        "mobile": this.state.mobile,
        "password": this.state.password
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.login, payload, false);

      if (login.code == 200) {
        localStorage.setItem("userData", JSON.stringify(login.message));
        this.setState({ user: login.message });

        history.push('/');
        window.location.href = "/";
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  ActivateAccount = async () => {
    if (this.state.code === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var dat = localStorage.getItem("activationMobile");
      dat = JSON.parse(dat);
      var payload = {
        "mobile": dat.mobile,
        "code": this.state.code
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.verify, payload, false);

      if (login.code == 200) {
        this.setState({ user: login.message });
        var login1 = await PostData(urls.login, dat, false);

        if (login1.code == 200) {
          localStorage.setItem("userData", JSON.stringify(login1.message));
          this.setState({ user: login1.message });

          history.push('/');
          window.location.href = "/";
        } else {
          var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Input alert!</strong> {login.message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          this.setState({ activationMsg: loginMsg });
        }
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ activationMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  render() {
    const { loginMsg, loginLoading, userPoints, position, userSpins, playlist,
      purchaseMsg, purchaseLoading, activationMsg } = this.state;
    let playlistItems = [];
    for (var p in playlist) {
      const data = playlist[p];
      let selected = false;
      if (this.state.buySelection.id != undefined)
        if (this.state.buySelection.id == data.id)
          selected = true;


      if (selected == false) {
        playlistItems.push(<a href="#" onClick={() => this.setState({ buySelection: data })}>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {data.name} - {data.plays}plays
            <span className="badge badge-success badge-pill">¢{data.price}</span>
          </li></a>);
      } else {
        playlistItems.push(<li className="list-group-item d-flex justify-content-between align-items-center blk-bg">
          {data.name} - {data.plays}plays
          <span className="badge badge-success badge-pill">¢{data.price}</span>
        </li>);
      }
    }

    return (
      <div id="wrapper">

        <Sidemenu />
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

            <TopNav />

            {/* Leader board / notices */}
            <div className="container d-none d-sm-block">
              <div className="row grey-border b-pa  ">
                <div className="col-sm r-line">
                  <a href="/howitworks"><img className="img-fluid  mt-3  howitworks" style={{ width: "25rem" }} src="img/howitworks.png" alt="..." /></a>
                </div>

                <div className="col-sm r-line score-tip  ">
                  <div className="score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">My Points</h6>
                  </div>
                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your daily points
                      <span className="badge badge-primary badge-pill">{userPoints}</span>
                    </li>

                  </ul>

                </div>

                <div className="col-sm r-line ">
                  <div className="card-header score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">My Plays</h6>
                  </div>

                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your spins left
                      <span className="badge badge-primary badge-pill">{userSpins}</span>
                    </li>

                  </ul>


                </div>

                <div className="col-sm ">

                  <div className="card-header score-bg">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {(this.state.loggedin==true)?<a href="/leaderboard">Leaderboard</a>:<a href="#">Leaderboard</a>}
                    </h6>
                  </div>

                  <ul className="list-group topscore">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Your position is
                      <span className="badge badge-primary badge-pill">{position}</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            {/* leader board / notices end */}

            <div className="container"><hr /></div>

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
    <Modals/>
      </div>
    );
  }
}
