
const basePath = "https://api.ncartabox.com/";
export const imgPath = "https://api.ncartabox.com/static/";
const base = basePath + "api/v1/";
export const baseUrl = base;
export const imageUrl = basePath + "static/";
export const currency = "\u20A6";

export const urls = {
    user: 'users/user',
    login: 'users/login',
    fgt: 'users/requestreset/',
    reset: 'users/reset',
    recovery: 'users/recovery/',
    verify: 'users/verify',
    fcm: 'users/fcm',
    resetpassword: 'users/resetpassword',
    register: 'users/register',
    spins: 'users/spins',
    points: 'users/points',
    game: 'users/game',
    playlist: 'users/playlist',
    question: 'users/games',
    purchasePlays: 'users/purchase',
    position: 'users/leaderboardPositon',
    leaderboard: 'users/leaderboard',
    history: 'users/history/',
    challenges: 'users/challenges',
    purchaseHistory: 'users/purchaseHistory',
    prizesHistory: 'users/prizesHistory',
    pointHistory: 'users/pointHistory',
    rewards: 'users/rewards',
    feedback:'users/feedback'
}

export const messages = {
    defaultError: {
        status: 99,
        message: 'Sorry we are not able to process this at this time, kindly retry',
    },
    loginError: {
        error: true,
        message: 'Sorry we are not able to process this at this time, kindly retry',
    },
};

export function formatDate(date) {
    if (date !== null) return date.substring(0, 25);
    else return '';
}

/**
 Get the baseUrl from the server
 */
export function getUserData() {
    let userData = localStorage.getItem('userData');
    let user = JSON.parse(userData);
    return user;
}

/**
 Check if the string is a json data
 */
export function isJSONValid(data) {
    try {
        JSON.parse(data);
        return true;
    } catch (error) {
        return false;
    }
}

/**
 Post message
 @params url, data
 */
export async function PostData(url, data, sid) {
    try {
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (sid === true) {
            let user = await getUserData();
            myHeaders.append("access-token", user.access_token);
            myHeaders.append("user-id", user.id);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        return await fetch(base + url, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (isJSONValid(result)) {
                    return JSON.parse(result);
                } else {
                    return messages.defaultError;
                }
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        console.log(error);
        return messages.defaultError;
    }
}

/**
 Put Requests
 **/

export async function PutData(url, data) {
    try {
        let user = await getUserData();
        var headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        headers['access-token'] = user.access_token;
        headers['user-id'] = user.id;

        console.log('url==>', base + url);
        console.log('headers==>', headers);
        return await fetch(base + url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => response.text())
            .then((result) => {
                if (isJSONValid(result)) {
                    return JSON.parse(result);
                } else {
                    return messages.defaultError;
                }
            })
            .catch((error) => {
                console.log(error);
                return messages.defaultError;
            });
    } catch (error) {
        console.log(error);
        return messages.defaultError;
    }
}

export async function GetData(url) {
    try {
      let user = await getUserData();
  
      var header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
  
      if (user !== null) {
        header['user-id'] = user.id;
        header['access-token'] = user.access_token;
      }
  
      console.log("header==>", header);
      console.log("endpoint==>", base + url);
      return await fetch(base + url, {
        method: 'GET',
        headers: header
      }).then(response => response.text())
        .then(result => {
          console.log("result==>", result);
          if (isJSONValid(result)) {
            return JSON.parse(result);
          } else {
            return messages.defaultError;
          }
        })
        .catch(error => {
          console.log(error);
          return messages.defaultError;
        });
    } catch (error) {
      console.log(error);
      return messages.defaultError;
    }
  }

/**
 Delete
 **/
export async function DeleteData(url) {
    try {
        let user = await getUserData();
        var header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        header['access-token'] = user.public_key;
        header['merchant-id'] = user.merchant_id;

        console.log(base + url);
        return await fetch(base + url, {
            method: 'DELETE',
            headers: header,
        })
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                if (isJSONValid(result)) {
                    return JSON.parse(result);
                } else {
                    return messages.defaultError;
                }
            })
            .catch((error) => {
                return messages.defaultError;
            });
    } catch (error) {
        console.log(error);
        return messages.defaultError;
    }
}

/**
 Encryption
 */
// export async function appSecret(timeStamp){
//   let message="";
//   message = appId+":"+timeStamp;
//   const messageArrayBuffer = RNSimpleCrypto.utils.convertUtf8ToArrayBuffer(
//     message
//   );
//   const keyArrayBuffer = RNSimpleCrypto.utils.convertUtf8ToArrayBuffer(
//     appKey
//   );
//   const signatureArrayBuffer = await RNSimpleCrypto.HMAC.hmac256(messageArrayBuffer, keyArrayBuffer);

//   const signatureHex = RNSimpleCrypto.utils.convertArrayBufferToHex(
//     signatureArrayBuffer
//   );
//   return signatureHex;
// }
