import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import TopNav from '../components/TopNav';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import history from '../history';
import $ from "jquery";
import moment from 'moment';

export default class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      mobile: "",
      password: "",
      userPoints: "",
      position: "",
      userSpins: "",
      playlist: [],
      buySelection: {},
      purchaseMsg: "",
      selectednetwork: "",
      purchaseLoading: false,
      activationMsg: "",
      code: "",
      confpassword: "",
      feedbackMsg: "",
      feedMsg: ""
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();

    if (user !== undefined || user !== null) {
      this.setState({ user: user, loggedin: true });

      var userPoints = await GetData(urls.points);
      if (userPoints.code === 200) {
        this.setState({ userPoints: userPoints.message });
      }

      var userSpins = await GetData(urls.spins);
      if (userSpins.code === 200) {
        this.setState({ userSpins: userSpins.message });
      }

      var userPosition = await GetData(urls.position);
      if (userPosition.code === 200) {
        this.setState({ position: userPosition.message });
      }

      var playlist = await GetData(urls.playlist);
      if (playlist.code === 200) {
        this.setState({ playlist: playlist.message });
      }
    }

    var feedback = await GetData(urls.feedback);
    if (feedback.message == false)
      window.$("#feedbackModal").modal("show");
  };

  feedbackMsg = async () => {
    if (this.state.feedbackMsg == "") {
      var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Process alert!</strong> Kindly enter feedback to send
      </div>
      this.setState({ feedMsg: loginMsg });
    } else {
      this.setState({ loginLoading: true });
      var purchasing = await PostData(urls.feedback, {
        message: this.state.feedbackMsg,
      }, true);
      if (purchasing.code == 200) {
        var loginMsg = <div className="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> {purchasing.message}
        </div>
        this.setState({ feedMsg: loginMsg });
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Process error!</strong> {purchasing.message}
        </div>
        this.setState({ feedMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  payNow = async () => {
    this.setState({ purchaseLoading: true });

    var purchasing = await PostData(urls.purchasePlays, {
      network: this.state.selectednetwork,
      package: this.state.buySelection.id,
      voucher_code: '',
      amount: this.state.buySelection.price
    }, true);
    if (purchasing.code == 200) {
      var loginMsg = <div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Process alert!</strong> {purchasing.message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ purchaseMsg: loginMsg });
      this.loadData();
      var self = this;
      this.timeoutHandle = setTimeout(() => {
        self.setState({ buySelection: {}, purchaseMsg: "" });
        window.$("#silverplay").modal("hide");
      }, 7000);
    } else {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Process alert!</strong> {purchasing.message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ purchaseMsg: loginMsg });
    }
    this.setState({ purchaseLoading: false });
  }


  Registration = async () => {
    if (this.state.mobile === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.mobile.length !== 10) {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is invalid eg 0202000000
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.password === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var payload = {
        "mobile": this.state.mobile,
        "screen_name": this.state.name,
        email: "",
        "password": this.state.password
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.user, payload, false);

      if (login.code == 200) {
        localStorage.setItem("activationMobile", JSON.stringify(payload));
        this.setState({ user: login.message });
        window.$("#registerModal").modal("hide");
        window.$("#activationModal").modal("show");

      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  recovery = async () => {
    if (this.state.mobile === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.mobile.length !== 10) {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {

      this.setState({ loginLoading: true });
      var login = await GetData(urls.recovery + this.state.mobile);

      if (login.code == 200) {
        var loginMsg = <div className="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
        this.timeoutHandle = setTimeout(() => {
          window.$("#recoveryModal").modal("hide");
          window.$("#resetModal").modal("show");
        }, 5000);
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }


  logginIn = async () => {
    if (this.state.mobile === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.password === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var payload = {
        "mobile": this.state.mobile,
        "password": this.state.password
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.login, payload, false);

      if (login.code == 200) {
        if (login.status === undefined) {
          localStorage.setItem("userData", JSON.stringify(login.message));
          this.setState({ user: login.message });

          history.push('/');
          window.location.href = "/";
        } else {
          if (login.status === 0) {
            localStorage.setItem("activationMobile", JSON.stringify(payload));
            this.setState({ user: payload });
            window.$("#loginModal").modal("hide");
            window.$("#activationModal").modal("show");
          } else {
            localStorage.setItem("userData", JSON.stringify(login.message));
            this.setState({ user: login.message });

            history.push('/');
            window.location.href = "/";
          }
        }
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ loginMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  ActivateAccount = async () => {
    if (this.state.code === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> mobile number is required
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var dat = localStorage.getItem("activationMobile");
      dat = JSON.parse(dat);
      var payload = {
        "mobile": dat.mobile,
        "code": this.state.code
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.verify, payload, false);

      if (login.code == 200) {
        this.setState({ user: login.message });
        var login1 = await PostData(urls.login, dat, false);

        if (login1.code == 200) {
          localStorage.setItem("userData", JSON.stringify(login1.message));
          this.setState({ user: login1.message });

          history.push('/');
          window.location.href = "/";
        } else {
          var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Input alert!</strong> {login.message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          this.setState({ activationMsg: loginMsg });
        }
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ activationMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  resetAccount = async () => {
    if (this.state.code === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> code is require
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else if (this.state.password === "") {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> password is require
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    }
    else if (this.state.password !== this.state.confpassword) {
      var loginMsg = <div className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Input alert!</strong> passwords do not match
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      this.setState({ loginMsg: loginMsg });
    } else {
      var payload = {
        "mobile": this.state.mobile,
        "password": this.state.password,
        "code": this.state.code
      };

      this.setState({ loginLoading: true });
      var login = await PostData(urls.reset, payload, false);

      if (login.code == 200) {
        this.setState({ user: login.message });
        var login1 = await PostData(urls.login, {
          "mobile": this.state.mobile,
          "password": this.state.password
        }, false);

        if (login1.code == 200) {
          localStorage.setItem("userData", JSON.stringify(login1.message));
          this.setState({ user: login1.message });

          history.push('/');
          window.location.href = "/";
        } else {
          var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Input alert!</strong> {login.message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          this.setState({ activationMsg: loginMsg });
        }
      } else {
        var loginMsg = <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Input alert!</strong> {login.message}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        this.setState({ activationMsg: loginMsg });
      }
      this.setState({ loginLoading: false });
    }
  }

  render() {
    const { loginMsg, loginLoading, userPoints, position, userSpins, playlist,
      purchaseMsg, purchaseLoading, activationMsg, feedMsg } = this.state;
    let playlistItems = [];
    for (var p in playlist) {
      const data = playlist[p];
      let selected = false;
      if (this.state.buySelection.id != undefined)
        if (this.state.buySelection.id == data.id)
          selected = true;


      if (selected == false) {
        playlistItems.push(<a href="#" onClick={() => this.setState({ buySelection: data })}>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {data.name} - {data.plays}plays
            <span className="badge badge-success badge-pill">¢{data.price}</span>
          </li></a>);
      } else {
        playlistItems.push(<li className="list-group-item d-flex justify-content-between align-items-center blk-bg">
          {data.name} - {data.plays}plays
          <span className="badge badge-success badge-pill">¢{data.price}</span>
        </li>);
      }
    }

    return (
      <div id="wrapper">

        {/* Logout Modal*/}
        <div className="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                <a className="btn btn-primary" href="login.html">Logout</a>
              </div>
            </div>
          </div>
        </div>

        {/* Buy play Modal */}
        <div className="modal fade" id="buyplay" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="exampleModalLongTitle">Buy Play</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* body */}
                <ul className="list-group ">

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Number of plays left
                    <span className="badge badge-success badge-pill">{userSpins} plays</span>
                  </li>
                </ul>



                <ul className="list-group playgroup">
                  {playlistItems}
                </ul>
                {/* body end */}

                <input type="text" className="form-control bg-light border-1 small"
                  placeholder="Custom Amount GHs 2-500" aria-label="Mobile"
                  value={this.state.name}
                  aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => {
                    const plays = ((Number(e.target.value) * 20) / 2).toFixed(0);
                    this.setState({
                      buySelection: {
                        "id": 4,
                        "name": "Custom",
                        "description": `${plays} plays for GHS ${e.target.value}`,
                        "plays": plays,
                        "price": e.target.value
                      }
                    })
                  }} />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => {
                  if (this.state.buySelection.id != undefined) {
                    // data-toggle="modal" data-target="#silverplay"
                    window.$("#buyplay").modal("hide");
                    window.$("#silverplay").modal("show");
                  }
                }} >Buy Now</button>
              </div>
            </div>
          </div>
        </div>
        {/* Buy play Modal end */}


        {/* silver play Modal */}
        <div className="modal fade" id="silverplay" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">You are about to buy a {this.state.buySelection.name}</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                {/* body */}
                <ul className="list-group ">

                  <li className="list-group-item d-flex justify-content-between align-items-center blk-bg">
                    {this.state.buySelection.plays} plays for
                    <span className="badge badge-success badge-pill">¢{this.state.buySelection.price}</span>
                  </li>
                  {(purchaseMsg !== "") &&
                    purchaseMsg
                  }


                </ul>

                <div className="list-group networks">


                  <button type="button" className="list-group-item list-group-item-action momo"
                    onClick={() => this.setState({ selectednetwork: "MTN" })}
                    style={{ backgroundColor: (this.state.selectednetwork == "MTN") ? "#ffcb05" : "" }}>
                    <a href="#" style={{ color: (this.state.selectednetwork == "MTN") ? "#fff" : "" }}>
                      <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                        <img className="mr-3" src="img/momo.jpg" alt="" width="88" height="" />
                        <div className="lh-100">
                          <h6 className="mb-0  lh-100">MTN Mobile Money</h6>
                          <small>Direct debit</small>
                        </div>
                      </div>
                    </a>
                  </button>

                  <button type="button" className="list-group-item list-group-item-action voda"
                    onClick={() => this.setState({ selectednetwork: "VOD" })}
                    style={{ backgroundColor: (this.state.selectednetwork == "VOD") ? "#e81a1a" : "" }}>
                    <a href="#" style={{ color: (this.state.selectednetwork == "VOD") ? "#fff" : "" }}>
                      <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                        <img className="mr-3" src="img/vodacash.jpg" alt="" width="88" height="" />
                        <div className="lh-100">
                          <h6 className="mb-0  lh-100">Vodafon Cash</h6>
                          <small>Direct debit</small>
                        </div>
                      </div>
                    </a>
                  </button>

                  <button type="button" className="list-group-item list-group-item-action tigo"
                    onClick={() => this.setState({ selectednetwork: "TIG" })}
                    style={{ backgroundColor: (this.state.selectednetwork == "TIG") ? "#203a7a" : "" }}>
                    <a href="#" style={{ color: (this.state.selectednetwork == "TIG") ? "#fff" : "" }}>
                      <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                        <img className="mr-3" src="img/tigo.jpg" alt="" width="88" height="" />
                        <div className="lh-100">
                          <h6 className="mb-0  lh-100">Tigo Cash</h6>
                          <small>Direct debit</small>
                        </div>
                      </div>
                    </a>
                  </button>

                  <button type="button" className="list-group-item list-group-item-action airtel" onClick={() => this.setState({ selectednetwork: "AIR" })}
                    style={{ backgroundColor: (this.state.selectednetwork == "AIR") ? "#e81a1a" : "" }}>
                    <a href="#" style={{ color: (this.state.selectednetwork == "AIR") ? "#fff" : "" }}>
                      <div className="d-flex align-items-center p-3 my-3  bg-purple rounded box-shadow">
                        <img className="mr-3" src="img/airtel.jpg" alt="" width="88" height="" />
                        <div className="lh-100">
                          <h6 className="mb-0  lh-100">Airtel Cash</h6>
                          <small>Direct debit</small>
                        </div>
                      </div>
                    </a>
                  </button>



                </div>

              </div>
              {(purchaseLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.payNow()}>Pay Now</button>
                </div> : <div className="modal-footer"><p></p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

        <div className="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">Login</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(loginMsg != "") &&
                    loginMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Login with your mobile number and password.</small>
                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Mobile Number" aria-label="Mobile"
                    value={this.state.mobile}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ mobile: e.target.value })} />

                  <input type="password" className="form-control bg-light border-1 small"
                    placeholder="Password" aria-label="Mobile"
                    value={this.state.password}
                    aria-describedby="basic-addon2" style={{ marginTop: 10 }} onChange={(e) => this.setState({ password: e.target.value })} />

                  <a style={{ marginTop: 20 }} onClick={() => {
                    window.$("#loginModal").modal("hide");
                    window.$("#recoveryModal").modal("show");
                  }}><small>Forgotten your password?</small></a>
                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.logginIn()}>Login</button>
                </div> : <div className="modal-footer"><p>Logging in</p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

        <div className="modal fade" id="recoveryModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">Forgotten Password</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(loginMsg != "") &&
                    loginMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Enter your mobile number to request password recovery.</small>
                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Mobile Number" aria-label="Mobile"
                    value={this.state.mobile}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ mobile: e.target.value })} />

                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.recovery()}>Submit</button>
                </div> : <div className="modal-footer"><p>Logging in</p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>


        <div className="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">Register</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(loginMsg != "") &&
                    loginMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Login with your mobile number and password.</small>
                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Full Name" aria-label="Mobile"
                    value={this.state.name}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ name: e.target.value })} />

                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Mobile Number" aria-label="Mobile"
                    value={this.state.mobile}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ mobile: e.target.value })} />

                  <input type="password" className="form-control bg-light border-1 small"
                    placeholder="Password" aria-label="Mobile"
                    value={this.state.password}
                    aria-describedby="basic-addon2" style={{ marginTop: 10 }} onChange={(e) => this.setState({ password: e.target.value })} />

                  <a style={{ marginTop: 20 }}><small>Forgotten your password?</small></a>
                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.Registration()}>Register</button>
                </div> : <div className="modal-footer"><p>Logging in</p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

        <div className="modal fade" id="activationModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">Account Activation</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(activationMsg != "") &&
                    activationMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Enter your activation code to get in.</small>
                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Activation Code" aria-label="Mobile"
                    value={this.state.code}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ code: e.target.value })} />

                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.ActivateAccount()}>Submit</button>
                </div> : <div className="modal-footer"><p></p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

        <div className="modal fade" id="resetModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">Account Activation</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(activationMsg != "") &&
                    activationMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Enter your rest code and your new password to proceed.</small>
                  <input type="text" className="form-control bg-light border-1 small"
                    placeholder="Code" aria-label="Mobile"
                    value={this.state.code}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ code: e.target.value })} />

                  <input type="password" className="form-control bg-light border-1 small"
                    placeholder="Password" aria-label="Mobile"
                    value={this.state.password}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ password: e.target.value })} />

                  <input type="password" className="form-control bg-light border-1 small"
                    placeholder="Confirm Password" aria-label="Mobile"
                    value={this.state.confpassword}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ confpassword: e.target.value })} />

                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.resetAccount()}>Submit</button>
                </div> : <div className="modal-footer"><p></p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

        <div className="modal fade" id="feedbackModal" tabindex="-1" role="dialog" aria-labelledby="buyplaymode" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-gradient-primary">
                <h5 className="modal-title" id="buyplaymode">User Feedback</h5>
                <button type="button" className="close wht" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body momotabs">
                <ul className="list-group ">
                  {(feedMsg != "") &&
                    feedMsg
                  }
                </ul>

                <div className="list-group networks">
                  <small>Give us your feedback</small>
                  <textarea type="text" className="form-control bg-light border-1 small"
                    placeholder="Help us make it better by giving us you feedback and suggestions" aria-label="Mobile"
                    value={this.state.feedbackMsg}
                    aria-describedby="basic-addon2" style={{ marginTop: 5 }} onChange={(e) => this.setState({ feedbackMsg: e.target.value })} ></textarea>
                </div>

              </div>
              {(loginLoading == false) ?
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn btn-prf btn-warning" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary btn btn-prf btn-warning" onClick={() => this.feedbackMsg()}>Submit</button>
                </div> : <div className="modal-footer"><p></p><Spinner bg={"#fff"} fill={"#444"} /></div>}
            </div>
          </div>
        </div>

      </div>
    );
  }
}
