import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import PlayMenu from '../components/PlayMenu';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import Modals from './Modals';
import history from '../history';
import $ from "jquery";
import moment from 'moment';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      mobile: "",
      password: "",
      userPoints: "",
      position: "",
      userSpins: "",
      playlist: [],
      buySelection: {},
      purchaseMsg: "",
      selectednetwork: "",
      purchaseLoading: false,
      activationMsg: "",
      code: "",
      play: false,
      counter: "",
      question: {},
      user_ans: "",
      correctness: false,
      ans: ""
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();

    if (user !== undefined || user !== null) {
      this.setState({ user: user, loggedin: true });

      var userPoints = await GetData(urls.points);
      if (userPoints.code === 200) {
        this.setState({ userPoints: userPoints.message });
      }

      var userSpins = await GetData(urls.spins);
      if (userSpins.code === 200) {
        this.setState({ userSpins: userSpins.message });
      }

      var userPosition = await GetData(urls.position);
      if (userPosition.code === 200) {
        this.setState({ position: userPosition.message });
      }

    }

    // this.setState({ play: true, counter: 100 }, () => this.countDown());
  };

  countDown = async () => {
    var self = this;

    setTimeout(function () {
      self.countStart();
    }, 100);

    var userSpins = await GetData(urls.spins);
    if (userSpins.code === 200) {
      this.setState({ userSpins: userSpins.message });
    }
  }

  countStart = async () => {
    if (Number(this.state.userSpins) > 0) {
      var self = this;
      if (this.state.counter == "--") {

      } else {
        var counter = Number(this.state.counter);

        if (Number(counter) > 0) {
          setTimeout(function () {
            var c = self.state.counter;
            if (c !== "--") {
              c--;
              self.setState({ counter: '.' });
              self.setState({ counter: c }, () => self.countDown());
            }
          }, 1000);
        } else if (counter == 0) {
          if (this.state.question.id !== undefined) {
            this.answer();
          } else
            this.getQuestion();
        }
      }
    } else {
      this.setState({ counter: "--", play: false });
      window.$("#buyplay").modal("show");
    }
  }

  getQuestion = async () => {
    var question = await GetData(urls.question);
    if (question.code === 200) {
      var q=question.message;
      var anss=[];
      for (var ans in question.message) {
        if (ans.startsWith("ans")) {
          anss.push(question.message[ans]);
        }
      }

      var answers=this.shuffleAnswers(anss);
      answers=this.shuffleAnswers(answers);
      q["answers"]=answers;
      this.setState({ question: q, user_ans: "", counter: 60, correctness: false, ans: "" });
      this.countDown();
    }
  }

  answer = async (answer) => {
    var self = this;
    var question = await PostData(urls.question, {
      question: this.state.question.id,
      answer: answer
    }, true);

    if (question.code === undefined) {
      history.push('/play');
      window.location.href = window.location.href;
    } else {
      if (question.code == 200) {
        if (question.correctness == true) {
          this.setState({ correctness: question.correctness });
        }
      } else if (question.code == 302) {
        window.$("#buyplay").modal("show");

        this.setState({ correctness: question.correctness });
      }

      if (question.ans !== undefined) {
        this.setState({ ans: question.ans });
      }

      self.setState({ counter: "--" });
      self.loadData();
      setTimeout(function () {
        self.getQuestion();
      }, 3000);
    }
    this.setState({ user_ans: answer });
  }

  shuffleAnswers(sourceArray){
    for (var i = 0; i < sourceArray.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (sourceArray.length - i));

      var temp = sourceArray[j];
      sourceArray[j] = sourceArray[i];
      sourceArray[i] = temp;
   }
   return sourceArray;
  }

  render() {
    const { user_ans, loginMsg, loginLoading, userPoints, position, userSpins, playlist,
      purchaseMsg, purchaseLoading, activationMsg } = this.state;

    let answers = [];
    if (this.state.question.question != undefined) {
      
      for (var ans in this.state.question["answers"]) {
      //   if (ans.startsWith("ans")) {
          const data = this.state.question["answers"][ans];
          if (data != "") {
            if (user_ans !== "" && data == user_ans) {
              if (this.state.correctness == true) {
                answers.push(<a style={{ fontSize: 18, fontWeight: 'bold' }} class="list-group-item list-group-item-action rounded-pill correct">{data} <i class="fas fa-check"></i></a>);
              } else
                answers.push(<a style={{ fontSize: 18, fontWeight: 'bold' }} onClick={() => this.answer(data)} class="list-group-item list-group-item-action rounded-pill wrong">{data} <i class="fas fa-times"></i></a>);

            } else if (user_ans != "") {
              answers.push(<a style={{ fontSize: 18, fontWeight: 'bold' }} class="list-group-item list-group-item-action rounded-pill no-state">{data}</a>);
            } else
              answers.push(<a style={{ fontSize: 18, fontWeight: 'bold' }} onClick={() => this.answer(data)} class="list-group-item list-group-item-action rounded-pill no-state">{data}</a>);
          }
      //   }
      }
    }

    return (
      <div id="wrapper">

        <Sidemenu />
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

            <PlayMenu />

            {/* questions */}

            {(this.state.play == true) ?
              <div className="container">
                <div className="row">
                  <div className="col-xs d-none d-md-block d-sm-none">
                    <div className="card-body timer red" style={{ width: 150 }}>
                      <div className="">
                        <p>{this.state.counter}</p>
                      </div>
                      <div className="t-center">seconds
                      </div>
                    </div>
                    <div className="card-body timer " style={{ width: 150, marginTop: 5, backgroundColor: '#1c2c57', padding: '0.75rem' }}>
                      <div className="">
                        <p>{this.state.userPoints}</p>
                      </div>
                      <div className="t-center">points
                      </div>
                    </div>
                    <div className="card-body timer" style={{ width: 150, marginTop: 5, border: '5px solid #1c2c57' }}>
                      <div className="">
                        <p style={{ color: '#1c2c57' }}>{this.state.userSpins}</p>
                      </div>
                      <div className="" style={{ color: '#000 !important', textAlign: 'center' }}>plays left
                      </div>
                    </div>

                  </div>
                  <div className="col-xs d-md-none d-sm-block" style={{ flex: '0 0 100%' }}>

                    <div className="card-body smtimer " style={{ width: '100%', marginTop: 5, backgroundColor: '#1c2c57', padding: "3px 20px" }}>
                      <div className="">
                        <p style={{ color: '#fff' }}><b style={{ fontWeight: 700, fontSize: 24 }}>{this.state.userPoints}</b> <small>points</small></p>
                      </div>
                    </div>
                    <div className="card-body smtimer" style={{ width: '100%', marginTop: 5, border: '5px solid #1c2c57', padding: "0 20px" }}>
                      <div className="">
                        <p style={{ color: '#1c2c57' }}><b style={{ fontWeight: 700, fontSize: 24 }}>{this.state.userSpins}</b> <small>plays left</small></p>
                      </div>
                    </div>
                    <div className="card-body smtimer red" style={{ width: '100%', padding: "3px 20px", marginTop: 5, marginBottom: 5 }}>
                      <div className="">
                        <p><b style={{ fontWeight: 700, fontSize: 24, color: '#fff' }}>{this.state.counter}</b> <small style={{ color: '#fff' }}>seconds</small></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg qborder">
                    <div className="card-header py-3 question">
                      <h6 className=" ">{(this.state.question.id !== undefined) ? this.state.question.question : ""}</h6>
                    </div>
                    {(this.state.ans !== "") &&
                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>Correct Answer: </strong> {this.state.ans}

                      </div>}
                    <div className="list-group quiz">
                      {answers}
                    </div>
                  </div>
                </div>
                {/* questions-end */}




              </div> : <div className="container">
                <div className="row">
                  <div className="col-xs d-md-none d-sm-block" style={{ flex: '0 0 100%' }}>

                    <div className="card-body smtimer " style={{ width: '100%', marginTop: 5, backgroundColor: '#1c2c57', padding: "3px 20px" }}>
                      <div className="">
                        <p style={{ color: '#fff' }}><b style={{ fontWeight: 700, fontSize: 24 }}>{this.state.userPoints}</b> <small>points</small></p>
                      </div>
                    </div>
                    <div className="card-body smtimer" style={{ width: '100%', marginTop: 5, border: '5px solid #1c2c57', padding: "0 20px" }}>
                      <div className="">
                        <p style={{ color: '#1c2c57' }}><b style={{ fontWeight: 700, fontSize: 24 }}>{this.state.userSpins}</b> <small>plays left</small></p>
                      </div>
                    </div>
                    <div className="card-body smtimer red" style={{ width: '100%', padding: "3px 20px", marginTop: 5, marginBottom: 5 }}>
                      <div className="">
                        <p><b style={{ fontWeight: 700, fontSize: 24, color: '#fff' }}>{this.state.counter}</b> <small style={{ color: '#fff' }}>seconds</small></p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">

                  <div className="col-lg qborder " style={{ textAlign: 'center' }}>

                    <h5 style={{ color: '#ff003c', margin: '40px', fontWeight: 700 }}>You have 60 secs to answer each question.</h5>
                    <a href="#" onClick={() => {
                      this.setState({ play: true, counter: 5 }, () => this.countDown());

                    }}><h5 style={{ margin: '40px', fontWeight: 700 }}>Click to start</h5></a>

                  </div>
                </div>
                {/* questions-end */}

              </div>}

            <hr />

            {/* </div> */}

            {/* End of Main Content */}

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        <Modals />
      </div>
    );
  }
}
