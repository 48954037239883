import React, { Component } from 'react';
import '../App.css';
import Sidemenu from '../components/Sidemenu';
import TopMenu from '../components/TopMenu';
import { getUserData, PostData, GetData, urls } from "../common";
import Spinner from '../Spinner.js';
import history from '../history';
import $ from "jquery";
import moment from "moment";

export default class DailyPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      user: {},
      profilePic: '',
      loggedin: false,
      loginMsg: "",
      loginLoading: false,
      purchaseHistory: [],
      loading: true,
      index: 1,
      original:[]
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    var user = await getUserData();

    if (user !== null) {
      this.setState({ user: user, loggedin: true });

      this.setState({ loading: true });
      var purchaseHistory = await GetData(urls.rewards);
      if (purchaseHistory.code === 200) {
        // this.setState({ purchaseHistory: purchaseHistory.message });
        var history=this.state.purchaseHistory;
        var original=this.state.original;
        for(var h in purchaseHistory.message){
          const data=purchaseHistory.message[h];
          if(!original.includes(JSON.stringify(data))){
            history.push(data);
            original.push(JSON.stringify(data));
          }
        }
        this.setState({ purchaseHistory: history,original:original});
      
      }

      
      
      this.setState({ loading: false });
    }
  };

  loadMore=async()=>{
    var ind=this.state.index;
    ind=ind+1;
    this.setState({index:ind},()=>this.loadData());
  }

  pos=async(pos)=>{
    if(pos.endsWidth("1")){
      return pos+"st";
    }else if(pos.endsWidth("2")){
      return pos+"nd";
    }else if(pos.endsWidth("3")){
      return pos+"rd";
    }else
      return pos;
  }

  render() {
    const { purchaseHistory } = this.state;
    let statuses=["Pending","Processing","Completed"]

    return (
      <div id="wrapper">

        <Sidemenu />
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* Main Content */}
          <div id="content">

            <TopMenu />

            <div className="container"><hr /></div>


            {/* questions */}
            {(this.state.loading == true) && <center><Spinner bg={"#fff"} fill={"#444"} /><br /></center>}
            <div className="container">
              <div className="row">

                <div className="col-lg qborder">
                  <div className="card-header question">
                    <h6 className=" ">My Rewards</h6>
                  </div>
                  <div className="list-group quiz">
                    <table width={"100%"}>
                      <thead>
                        <th>Reward</th>
                        <th>Points</th>
                        {/* <th>Status</th> */}
                        <th>Datetime</th>
                      </thead>
                      <tbody>
                        {purchaseHistory.map(function (data, index) {
                          return (<tr>
                            <td>GHS<b>{data.amount}</b></td>
                            <td>{data.points} points</td>
                            {/* <td>{statuses[data.status]}</td> */}
                            <td>{data.datetime}</td>
                          </tr>);
                        })}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
              {/* questions-end */}




            </div>

            <hr />

            {/* </div> */}

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; nCartaBox {moment().format("YYYY")}</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}

          </div>
          {/* End of Content Wrapper */}

        </div>
        {/* End of Page Wrapper */}

        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    );
  }
}
